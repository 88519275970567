import { Switch } from 'antd';
import UpdateEntity from '../../containers/update-entity';
import { formatDate } from '@arivaa-react/helpers/date-util';

import UserForm from './form';
import { Crud } from '../../containers/crud/crud';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { useAutoCallApi, useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import { useCrudData } from '../../containers/crud/crudContext';

export default function Users() {
  const { refresh } = useCrudData();
  const { data = [] } = useAutoCallApi(useSecureApi, url`roles`, {
    params: {
      all: true,
    },
  });

  const options = data.map((ele) => {
    return {
      label: ele.code,
      value: ele.code,
    };
  });
  return (
    <Crud api="users" FormComponent={UserForm}>
      <Crud.Header title="Clients">
        <Crud.Header.Actions.Add />
        <Crud.Header.Actions.Refresh />
      </Crud.Header>
      <Crud.Table
        actions={[...Table.RowActions.Common]}
        columns={[
          {
            title: translate('user.name.label'),
            dataIndex: 'displayName',
          },
          {
            title: translate('user.email.label'),
            dataIndex: 'email',
          },
          {
            title: translate('user.enabled'),
            dataIndex: 'disabled',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record.role &&
                      record.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={!record.disabled}
                        onChange={async (checked) => {
                          await updateEntity({ disabled: !checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: 'Email Verified',
            dataIndex: 'emailVerified',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record.role &&
                      record.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={record.emailVerified}
                        onChange={async (checked) => {
                          await updateEntity({ emailVerified: checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: 'App Login Enabled',
            dataIndex: 'appLoginEnabled',
            render: (_, record) => {
              return (
                <UpdateEntity
                  keyPropName="uid"
                  getChildren={({ loading, updateEntity }) => {
                    return record.role &&
                      record.role.toLowerCase() !== 'admin' ? (
                      <Switch
                        loading={loading}
                        checked={record.appLoginEnabled}
                        onChange={async (checked) => {
                          await updateEntity({ appLoginEnabled: checked });
                          refresh();
                        }}
                      />
                    ) : null;
                  }}
                />
              );
            },
          },
          {
            title: translate('user.createdAt'),
            dataIndex: 'createdAt',
            render: (createdAt) => {
              return <p>{formatDate(createdAt)}</p>;
            },
          },
        ]}
      >
        {/* <Crud.Table.TableActions.Common /> */}
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'displayName',
                inputProps: {
                  placeholder: 'Name',
                },
              },
              {
                type: 'email',
                name: 'email',
                inputProps: {
                  placeholder: 'Email',
                },
              },
              {
                type: 'text',
                name: 'company',
                inputProps: {
                  placeholder: 'Company',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </Crud>
  );
}
