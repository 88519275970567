import { Table as AntdTable, FloatButton } from 'antd';
import { useCrudData } from './crudContext';
import { RowActions } from './rowActions';
import { useCallback, useState } from 'react';
import { TableProvider } from './tableContext';
import { useToggleColumns } from './useToggleColumns';
import { ToggleColumns } from './toggleColumns';
import { TableHeader } from './tableHeader';
import { BulkRowActions } from './bulkRowActions';
import { CrudRecordProvider } from './recordContext';
import { CsvExport } from './csvExport';
const commonRowActions = [RowActions.Edit, RowActions.Delete];
export function Table({
  actions = commonRowActions,
  columns = [],
  pagination,
  persistToggleColumnState = false,
  enableSort = true,
  children,
  ...props
}) {
  const { data, loading, query, updateQuery, entity } = useCrudData();
  const { isColumnSelected, onToggleColumn } = useToggleColumns(
    entity,
    persistToggleColumnState
  );
  const [selection, setSelection] = useState({
    selectedRowKeys: [],
    selectedRows: [],
  });
  const onTableChange = useCallback((pagination, _, sorter) => {
    const { pageSize: size, current: page } = pagination;
    sorter = Array.isArray(sorter) ? sorter : [sorter];
    sorter = sorter.filter((ele) => ele.order != undefined);
    const sortQuery = sorter?.length
      ? {
          sort: sorter.map((ele) => ele.field),
          sortType: sorter.map((ele) => {
            if (ele.order === 'ascend') return 'asc';
            else if (ele.order === 'descend') return 'desc';
          }),
        }
      : {};
    updateQuery({
      page,
      size,
      ...sortQuery,
    });
  }, []);

  return (
    <TableProvider
      value={{
        columns,
        isColumnSelected,
        onToggleColumn,
        selection,
        setSelection,
        visibleColumns: columns.filter(isColumnSelected),
      }}
    >
      {children}
      <AntdTable
        bordered={true}
        rowKey={'key'}
        showSorterTooltip={false}
        tableLayout={data?.data?.length ? 'auto' : 'fixed'}
        scroll={data?.data?.length ? { x: 'max-content' } : undefined}
        rowSelection={{
          onChange: (selectedRowKeys, selectedRows) =>
            setSelection({ selectedRowKeys, selectedRows }),
        }}
        {...props}
        columns={[
          ...columns.filter(isColumnSelected).map((col, index) => ({
            ellipsis: true,
            sorter: enableSort
              ? {
                  multiple: index + 1,
                }
              : undefined,
            ...col,
          })),
          ...(actions.length > 0
            ? [
                {
                  title: 'Actions',
                  key: 'Actions',
                  render: (_, record) => (
                    <RowActions record={record} actions={actions} />
                  ),
                },
              ]
            : []),
        ].map(({ render, ...col }) => ({
          ...col,
          render: render
            ? (text, record) => (
                <CrudRecordProvider value={record}>
                  {render(text, record)}
                </CrudRecordProvider>
              )
            : undefined,
        }))}
        dataSource={data?.data}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: true,
          ...pagination,
          pageSize: query?.size,
          total: data?.total,
          current: query.page,
        }}
        onChange={onTableChange}
      />
    </TableProvider>
  );
}

Table.Header = TableHeader;
Table.RowActions = RowActions;

Table.TableActions = ({ children }) => {
  return <FloatButton.Group shape="square">{children}</FloatButton.Group>;
};

Table.BulkRowActions = BulkRowActions;

Table.TableActions.Common = ({ children }) => {
  return (
    <Table.TableActions>
      <ToggleColumns />
      <CsvExport />
      {children}
    </Table.TableActions>
  );
};

Table.TableActions.ToggleColumns = ToggleColumns;
Table.RowActions.Common = commonRowActions;
