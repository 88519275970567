import { useCallback, useEffect } from 'react';
import './styles.scss';
import { renderRoutes } from 'react-router-config';
import { addOnRouteChange, getCurrentRoute } from '@arivaa-react/react-router5';
import { isMobile, scrollToTopWindow } from '@arivaa-react/helpers/web';
import { useDrawer } from '../../hooks/useDrawer';
import { useHorizontalMenu } from '../../hooks/useHorizontalMenu';
import { useOnPageSetup } from '../../hooks/useOnPageSetup';
const Main = (props) => {
  const { route: propRoute } = props;
  const { horizontalMenu } = useHorizontalMenu();

  const route = getCurrentRoute();
  const { setDrawer } = useDrawer();
  const { header } = route;
  const { pageReady } = useOnPageSetup();
  const setViewPort = useCallback(() => {
    if (getCurrentRoute()?.routeKey === 'home.diagram' && isMobile()) {
      document.querySelector('meta[name="viewport"]').content = 'width=1420px';
    } else {
      document.querySelector('meta[name="viewport"]').content =
        'width=device-width, initial-scale=1.0, maximum-scale=2.0';
    }
  }, [route]);
  useEffect(() => {
    addOnRouteChange(() => {
      setTimeout(() => {
        if (isMobile()) {
          setDrawer(false);
        }
        scrollToTopWindow();
        setViewPort();
      }, 0);
    });
    setViewPort();
  }, []);
  return (
    <div className="app-container">
      {pageReady ? (
        <>
          {/* {header !== false ? <Header type={header} /> : null} */}
          <div
            className={
              'main-container ' +
              (!route.public ? 'secured ' : '') +
              (header == false
                ? ' no-header '
                : (header && header.type) || '') +
              (horizontalMenu ? ' with-horizontal-menu' : {})
            }
          >
            {renderRoutes(propRoute.routes)}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Main;
